.app{
    display:flex;
    flex-wrap: wrap;
}



body {
    font-family: poppins;
    font-size: 150%;
    background-color: #22487b;
    color: #e9e3e6;
    align-content: center;
    
}

a {
    text-decoration: none;
}

li {
    font-size: 150%;
}

.contentLeft {
    background-color: #22487b;
    color: #e9e3e6;
    padding: 0;
    margin: 0;
    width: 100vw;
    align-content:center;
    
}

.navBarElement{
    position: absolute;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}

.contentLeftText {
    display: inline-block;
    text-align: left;
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 2.5%;
    width: 50%;
    vertical-align: top;
}

.ourServicesElement {
    width: 100vw;
    padding: 0;
    margin: 0;
}

.contentImg {
    display: inline-block;
    align-self: left;
    align-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 40%;
    object-fit: cover;
}

.navBar {
    display: grid;
    grid-template-columns: 20% 80%;
    background-color: #22487b;
    align-content: center;
    align-self: center;
    width: 100%;
    color: white;
    position: fixed;
    top: 0;
    font-size: medium;
    padding-bottom: 2%;
    height: 20%;
    scroll-padding-top: 20%;
}

.navBarList {
    grid-column: 2;
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    text-decoration: underline;
    margin: auto;
    color: #e9e3e6;
}

.listItem {
    display: inline-block;
    width: 20%;
}

.listItem a{
    text-decoration: none;
    color: white;
}

.contact-us{
    display: grid;
    padding-left: 10%;
    padding-right: 10%;
}

.contact-info{
    grid-column: 1;;
}

.contact-img{
    grid-column: 2;
    width: 100%;
    height: auto;
    object-fit: fill;
}

.logo {
    grid-column: 1;
    padding-left: 20%;
}

.hamburger {
    display: none;
}

.contentRight{
    background-color: #68a600;
    color: #e9e3e6;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    align-content: center;
}


.ourServices{
    background-color: black;
    color: #e9e3e6;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.ourServices h4{
    text-align: center; 
    white-space: pre-wrap; 
    transition-timing-function: ease; 
    transition-duration: 0.9s; 
    transition-delay: 0.42s; 
    font-size: 150%;
}

.ourServices p{
    padding-bottom: 5%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 2.5%;
    font-size: 100%;
}

.news {
    display: flexbox;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.article {
    border-radius: 10vh;
    background-color:beige;
    color:black;
    font-size: 100%;
    text-decoration: none;
}

.articleText {
    padding-left: 30%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    align-self: center;
    font-size: 100%;
    color: black;
}

.articleText a{
    text-align: left;
    color: black;

}

.fade-in-image { animation: fadeIn 0.75s; 
    padding: 0;
    margin: 0;
    width: 100vw;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@media only screen and (max-width: 700px) {

    .navBar {
        display: grid;
        position: absolute;
        height: 10%;
        grid-template-columns: 40% 60%;
        grid-template-rows: 90% 10%;
        background-color: #22487b;
        align-content: center;
        vertical-align: auto;
    }

    .logo {
        position: absolute;
        grid-column: 1;
        align-self: left;
        align-items: left;
        height: 90%;
    }
    
    .navBarList {
        display: none;
        flex-direction: column;
        grid-row: 2;
        width: 100vw;
        
    }
    
    .listItem {
        display: inline-block;
        vertical-align: middle;
        font-size: medium;
        width: 100%;
        padding: 5%;
        background-color: #22487b;
    }
    
    .listItem a{
        text-decoration: none;
        text-align: center;
        color: white;
    }

    .hamburger {
        grid-column: 2;
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 40%;
        align-self: left;
        align-content:baseline;
        /* padding-right: 30%; */
        cursor: pointer;
    }
    
    .hamburger li {
        display: block;
        width: 50%;
        height: 3px;
        background-color: #000;
        margin-bottom: 5px;
        border-radius: 20%;
      }

      .contentRight{
        background-color: #68a600;
        color: #e9e3e6;
        display: grid;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        align-content: center;
    }

      .contentLeft {
        background-color: #22487b;
        color: #e9e3e6;
        padding: 0;
        margin: 0;
        width: 100vw;
        align-content:center;
        display: grid;        
    }
    

      .contentLeftText {
        grid-column: 1;
        text-align: left;
        padding-top: 5%;
        padding-left: 1%;
        padding-right: 2.5%;
        width: 100%;
        vertical-align: top;
    }

      .contentImg {
        grid-column: 2;
        align-self: left;
        align-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
        width: 40%;
        object-fit: cover;
        width: 100%;
    }
}